<template>
  <main class="main">
    <header class="card-header">
      <h1 class="body-bold">
        Bem-vindo{{ guestName ? ", " + guestName : "!" }}
      </h1>
    </header>
    <z-card width="100%" class="my-reservation">
      <template #content>
        <div class="card-title grid-span-2">
          {{ $t("reservation.reservation_info") }}
        </div>
        <div
          v-for="info of reservationInfo"
          :key="info.value"
          class="my-reservation-info"
        >
          <z-icon color="var(--primary)">{{ info.icon }}</z-icon>
          <div>
            <div v-if="info.label" class="body3">{{ info.label }}</div>
            <div
              v-if="info.value"
              class="body3-bold"
              :class="{ 'link-value': info.onClick }"
              @click="info.onClick || (() => {})"
            >
              {{ info.value }}
            </div>
            <div v-if="info.complement" class="body3">
              {{ info.complement }}
            </div>
          </div>
        </div>
      </template>
    </z-card>
    <z-card
      class="hotel-info"
      width="100%"
      :title="$t('reservation.hotel_info')"
    >
      <template #content>
        <template v-for="info of hotelInfo">
          <div
            v-if="!info.disabled"
            :key="info.value"
            class="my-reservation-info grid-span-2"
          >
            <z-icon color="var(--primary)">
              {{ info.icon }}
            </z-icon>
            <div>
              <div v-if="info.label" class="body3">{{ info.label }}</div>
              <div v-if="info.value" class="body3-bold">{{ info.value }}</div>
              <div v-if="info.complement" class="body3">
                {{ info.complement }}
              </div>
            </div>
          </div>
        </template>
        <z-btn
          primary
          class="grid-span-2"
          text="Ligar para o hotel"
          icon="$phone_rounded"
          @click="$refs.phoneLink.click()"
        />
        <a
          ref="phoneLink"
          :href="'tel:' + hotel.phoneNumber"
          style="display: none"
        ></a>
      </template>
    </z-card>
  </main>
</template>

<script>
export default {
  props: {
    guestName: {
      type: String,
      default: "Leonardo",
    },
    reservationInfo: {
      type: Array,
      required: true,
    },
    hotel: {
      type: Object,
      required: true,
    },
    hotelInfo: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.card-header {
  color: white;
  text-align: center;
}

.main {
  display: grid;
  column-gap: 15px;
  row-gap: 15px;
  width: 100%;
}

.my-reservation {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  max-height: 488px;
}

.hotel-info {
  display: grid;
  row-gap: 15px;
}

.my-reservation-info {
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  word-break: break-word;
}

.grid-span-2 {
  grid-column: span 2;
}

.link-value {
  color: var(--link-color);
  text-decoration: underline;
}
</style>
