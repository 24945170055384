<template>
  <reservation-info-mobile
    v-if="$vuetify.breakpoint.smAndDown"
    :guest-name="guest.name"
    :hotel-info="hotelInfo"
    :reservation-info="reservationInfo"
    :hotel="hotel"
  />
  <reservation-info-desktop
    v-else
    :guest-name="guest.name"
    :hotel-info="hotelInfo"
    :hotel="hotel"
    :reservation-info="reservationInfo"
  />
</template>

<script>
import dayjs from "dayjs"
import { useNamespacedState } from "vuex-composition-helpers/dist"

import { useVuetify } from "@/composables"
import i18n from "@/i18n"
import { formatDefaultMobile } from "@/utils/helpers"

import ReservationInfoDesktop from "./components/ReservationInfoDesktop.vue"
import ReservationInfoMobile from "./components/ReservationInfoMobile.vue"

export default {
  components: {
    ReservationInfoDesktop,
    ReservationInfoMobile,
  },
  setup() {
    const { hotel } = useNamespacedState("company", ["hotel"])
    const { reservation } = useNamespacedState("reservation", ["reservation"])
    const { guest } = useNamespacedState("guest", ["guest"])
    const vuetify = useVuetify()

    const checkin = dayjs(
      reservation.value.checkinDate.replace("Z", "")
    ).startOf("day")
    const checkout = dayjs(
      reservation.value.checkoutDate.replace("Z", "")
    ).startOf("day")
    const nights = checkout.diff(checkin, "days")
    const phoneNumber = formatDefaultMobile(hotel.value.phoneNumber)

    const onClickAddress = () => {}

    return {
      hotel,
      guest,
      hotelInfo: [
        {
          icon: "$business_rounded",
          label: i18n.tc("reservation.hotel").toUpperCase(),
          value: hotel.value.nomeFantasia,
          complement: "",
        },
        {
          icon: "$phone_rounded",
          label: phoneNumber,
          value: "",
          disabled: vuetify.breakpoint.smAndDown,
        },
        {
          icon: "$mail_rounded",
          label: hotel.value.email,
        },
        {
          icon: "$location_on_rounded",
          label: hotel.value.fullAddress,
        },
      ],
      reservationInfo: [
        {
          icon: "$assignment_rounded",
          label: i18n.tc("reservation.reservation_number").toUpperCase(),
          value: reservation.value.reservationNumber,
        },
        {
          icon: "$bed",
          label: i18n.tc("reservation.nights").toUpperCase(),
          value: `${nights} noite(s)`,
        },
        {
          icon: " $room_service_rounded",
          label: i18n.tc("reservation.check_in").toUpperCase(),
          value: checkin.format("DD/MM/YYYY"),
          complement: hotel.value.minCheckin
            ? `${i18n.tc("reservation.from")} ${hotel.value.minCheckin}h`
            : "",
        },
        {
          icon: "$plane",
          label: i18n.tc("reservation.check_out").toUpperCase(),
          value: checkout.format("DD/MM/YYYY"),
          complement: hotel.value.maxCheckout
            ? `${i18n.tc("reservation.until")} ${hotel.value.maxCheckout}h`
            : "",
        },
      ],
      onClickAddress,
    }
  },
}
</script>
