<template>
  <main class="main">
    <header class="card-header">
      <h1 class="body-bold">
        Bem-vindo{{ guestName ? ", " + guestName : "!" }}
      </h1>
    </header>
    <z-card width="100%" class="my-reservation">
      <template #content>
        <div class="card-title grid-span-2">
          {{ $t("reservation.reservation_info") }}
        </div>
        <div
          v-for="info of reservationInfo"
          :key="info.value"
          class="my-reservation-info"
        >
          <z-icon color="var(--primary)">{{ info.icon }}</z-icon>
          <div>
            <div v-if="info.label" class="body3">{{ info.label }}</div>
            <div
              v-if="info.value"
              class="body3-bold"
              :class="{ 'link-value': info.onClick }"
              @click="info.onClick || (() => {})"
            >
              {{ info.value }}
            </div>
            <div v-if="info.complement" class="body3">
              {{ info.complement }}
            </div>
          </div>
        </div>

        <v-divider class="grid-span-2"></v-divider>

        <div class="card-title grid-span-2">
          {{ $t("reservation.hotel_info") }}
        </div>
        <div
          v-for="info of hotelInfo"
          :key="info.value"
          class="my-reservation-info grid-span-2"
        >
          <z-icon color="var(--primary)">{{ info.icon }}</z-icon>
          <div>
            <div v-if="info.label" class="body3">{{ info.label }}</div>
            <div v-if="info.value" class="body3-bold">{{ info.value }}</div>
            <div v-if="info.complement" class="body3">
              {{ info.complement }}
            </div>
          </div>
        </div>
      </template>
    </z-card>
    <z-card v-if="false" width="100%" class="my-reservation">
      <div id="reservation-map" class="map-container"></div>
    </z-card>
  </main>
</template>

<script>
export default {
  props: {
    guestName: {
      type: String,
      required: true,
    },
    reservationInfo: {
      type: Array,
      required: true,
    },
    hotelInfo: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
.card-header {
  display: none;
}

.main {
  column-gap: 15px;
  display: grid;
  grid-template-columns: 1fr;
}

.my-reservation {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  max-height: 488px;
  max-width: 387px;
}

.my-reservation-info {
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
  word-break: break-word;
}

.grid-span-2 {
  grid-column: span 2;
}

.hotel-info {
  display: grid;
}

.link-value {
  color: var(--link-color);
  text-decoration: underline;
}

.map-container {
  grid-column: 3 / -1;
  grid-row: 1 / 6;
}
</style>
